import advTheme from "@wojo/theme/adv/adv-light.module.scss";
import gatTheme from "@wojo/theme/gat/gat-light.module.scss";
import ubTheme from "@wojo/theme/ub/ub-light.module.scss";
import { BusinessCode } from "../generated/graphql";

export function getThemeClassName() {
    switch (process.env.NEXT_PUBLIC_BUSINESS_CODE) {
        case BusinessCode.Ub:
            return ubTheme["ub-light"];
        case BusinessCode.Gat:
            return gatTheme["gat-light"];
        case BusinessCode.Adv:
            return advTheme["adv-light"];
        default:
            return gatTheme["gat-light"];
    }
}
